import React from 'react'
import Plx from "react-plx";
import backgroundImage from '../../images/backgroundimages/Assorted-colorful-glazed-donuts-1081149@0.33x.png'
import sidebottle from '../../images/donut/09_Cropped@0.3x.png'
export default function DonutFly() {
    const parallaxData = [
        {
          start: "self",
          startOffset: 0,
          duration: 1000,
          easing: [0.25, 0.1, 0.6, 1.5],
          properties: [
            {
              startValue: 90,
              endValue: 0,
              property: "rotate",
            },
            {
              startValue: 0.5,
              endValue: .95,
              property: "scale",
            },
          ],
        },
      ];
  return (
    <div className='noPatterBackground topShadow minScreenHeight p-5' style={{ backgroundImage: `url('${backgroundImage}')` }}>
        <Plx className="MyAwesomeParallax imgShadow minScreenHeight centeredFlex" parallaxData={parallaxData} animateWhenNotInViewport={true}>
        <img src={sidebottle} className='sidebottle'/>
      </Plx>


    </div>
  )
}
