
import Plx from "react-plx";
import backgroundImage from '../../images/backgroundimages/liquid.jpg'
import sidebottle from '../../images/vectordonut.svg'
import mainbottle from '../../images/donut/01_cropped@0.5x.png'
import React from 'react'

export default function DonutsFlying() {

       const parallaxData = [
        {
          start: "self",
          startOffset: 0,
          duration: 1000,
          animateWhenNotInViewport: true,
          easing: [0.25, 0.1, 0.6, 1.5],
          properties: [
            {
              startValue: -.1,
              endValue: .8,
              property: "scale",
            },
          ],
        },
      ];

  return (
    <div className='noPatterBackground topShadow' style={{ backgroundImage: `url('${backgroundImage}')`, minHeight: '60vh' }}>
        <div className='pt-5'>
        <Plx className="MyAwesomeParallax imgShadow" parallaxData={parallaxData} animateWhenNotInViewport={true}>

              <img src={mainbottle} className="above imgShadow"/>
    <img src={sidebottle} className='img-fluid'/>
  </Plx>
        </div>
      </div>
  )
}
