import React from 'react'
import heart from '../../images/heart.jpg'
export default function GalleryEdition() {
  return (
    <>
    <div>

    </div>
     
    <div className="minScreenHeight junegalSection">
    <h1 className='largeText1 text-white'>June Gal Edition</h1>

       
<img src={heart} className='JuneHeart'/>


    </div>
    <div className="juneGalText topShadow">

        <p>Hi Babe! On this special day, don’t count the candles on the cake, instead, count all the blessings. I promise that I will always be by your side to hold the fire extinguisher to help put out the candles. You certainly don’t look your age. And you don’t act like it either. They say gray hair is a sign of wisdom. Happy birthday to the wisest guy I know. I love you! 😘❤️ Your Baby Guh</p>
    </div>
    </>
  )
}
