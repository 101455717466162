import React from 'react'
import backgroundImage from '../../images/backgroundimages/multidonuts.jpg'
import { MDBBtn } from 'mdb-react-ui-kit';


export default function Advertisement1() {
  return (
    <div className='minScreenHeight noPatterBackground adDiscount topShadow' style={{ backgroundImage: `url('${backgroundImage}')`}}>
      {/* <div className='adverisementDisplay shadow-5-strong'>
      <h1>For a Limited Time</h1>
<p>use the code</p>
<h2> "HAPPY BIRTHDAY ALPHA"</h2>
<a href="https://forms.gle/VBjARdSGYZ9bM19H7" target="_blank" rel="noreferrer" >
<MDBBtn size="lg"> Add A Message</MDBBtn>
  </a>

      </div> */}


    </div>
  )
}
