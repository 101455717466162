import React from 'react'
import { MDBParallax } from 'mdb-react-parallax';
import { Parallax, Background } from 'react-parallax';
import backgroundImage from '../../images/backgroundimages/pushpak-dsilva-kD0_LMuCg3o-unsplash.jpg'
export default function Advertisement() {
  return (
    // <div className=''>
        

        <Parallax
        blur={{ min: 2, max: 5 }}
        bgImage={backgroundImage}
        className='bg-image minScreenHeight topShadow pb-4 pt-5'
        style={{ backgroundColor: 'black'}}
        // strength='100'
        
    >
        <div style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px'}}> 
        {/* <div className='mask' style={{ backgroundColor: 'rgba(249, 49, 84, 0.6)' }}></div> */}
        <div className='d-flex justify-content-center align-items-center h-100 pb-5'>
        <h1 className='jumboText text-white'> One Sprinkle Excites Everybody</h1>
        </div>
        
        </div>
    </Parallax>
    // </div>
  )
}
