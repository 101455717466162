import axios from "axios";
import React, {useState} from 'react'
import backgroundImage from '../../images/backgroundimages/blueback.png'
export default function Messages() {
// make a data varable that sets the state of the data
// make a useEffect function that calls the axios function
// make a axios function that gets the data from the google sheet           
// make a return function that returns the data
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

function getMessages() {
    axios({
        method: 'get',
        // url: 'https://v1.nocodeapi.com/dcartist/google_sheets/PjKfKITdhzWqtlPj?tabId=Form Responses 1', 
        url: 'https://v1.nocodeapi.com/dcartist/google_sheets/dKMVDtySsvTVvCwS?tabId=birthday', 
        params: {},
    }).then(function (response) {
            // handle success
            setData(response.data.data);
            console.log(response.data);
    }).catch(function (error) {
            // handle error
            setError(error);
            console.log(error);
    })

}

// create a use effect that calls getMessages
React.useEffect(() => {
    getMessages();
}, []);


  return (
    <div className="minScreenHeight messageBackground">

        <h1 className="largeText">Le Donut by Alpha Users:</h1>
        <div className="messageMainArea">
        {
            data && data.map((item, index) => {
                return (
                    <div key={index}>
                        <h2>{item['Name (Preferable Twitch Name)']}</h2>
                        <p>{item['Birthday Message']}</p>
                        <hr></hr>
                    </div>
                )
            })
        }


        </div>
       

    </div>
  )
}
