import React from 'react'
import backgroundImage from '../../images/backgroundimages/pinkdonut.jpg'
// import backgroundImage from '../../images/backgroundimages/pinkdonut.jpg'
import bottle1 from '../../images/donut/07_cropped@0.5x.png'
import bottle2 from '../../images/donut/06_cropped@0.5x.png'
import LeDonutWhite from '../../images/logo/introledonut_white';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
export default function Intro() {
  return (
    <div>  
          <div
        className='p-5 text-center bg-image minScreenHeight w-max'
        style={{ backgroundImage: `url('${backgroundImage}')` }}
      >
        <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          <div className='d-flex justify-content-center align-items-center minScreenHeight  w-100 '>
            <div className='text-white w-100'>
            <LeDonutWhite className='imgIntroScreenHeight'></LeDonutWhite>
            </div>
          </div>
        </div>
      </div>


</div>
  )
}
