import LeDonutWhite from './images/logo/ledonut_white';
import LeDonutBlack from './images/logo/ledonut_black';
import DonutFly from './Components/DonutFly/DonutFly';
import React, { useState, useEffect } from 'react'

import './App.css';
import Intro from './Components/Intro/Intro';
import Advertisement from './Components/Advertise/Advertisement';
import Advertisement1 from './Components/Advertise/Advertisement1';
import DonutsFlying from './Components/DonutFly/DonutsFlying';
import Messages from './Components/BirthdayMessge/Messages';
import GalleryEdition from './Components/DonutFly/GalleryEdition';
import NoRefunds from './Components/Advertise/NoRefunds';
function App() {

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => setLoading(false), 3000)
  }, [])

  return (
    <>
    {
      loading === false ? (
        <div className="App">
        <Intro></Intro>
        <DonutFly></DonutFly>
        <Advertisement></Advertisement>
        <DonutsFlying></DonutsFlying>
        <GalleryEdition></GalleryEdition>
        <Advertisement1></Advertisement1>
        <Messages></Messages>
        <NoRefunds></NoRefunds>
        </div>
      ) : (
        <div className="App Loading">
          <LeDonutWhite className='imgIntroScreenHeight'></LeDonutWhite>
          <p className='imgShadow'>
          Loading...</p>

          </div>
      )
    } 
    </>
  );
}

export default App;
