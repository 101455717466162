import React, { useState } from 'react';
import { Parallax } from 'react-parallax';
import backgroundImage from '../../images/backgroundimages/popcolor.jpg'

import bottle from '../../images/01_cropped.png'
import rickRoll from '../../images/rick-roll-rick-rolled.gif'
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';

export default function NoRefunds() {
    const [basicModal, setBasicModal] = useState(false);

  const toggleShow = () => setBasicModal(!basicModal);

  
  return (
    <div>

{/* Create a react-parallax */}
<Parallax

// blur={{ min: 2, max: 5 }}
bgImage={backgroundImage}
className='bg-image topShadow'
strength='100'
> 
<div className='minScreenHeight centeredFlex topShadow'>
<img src={bottle} className='img-fluid w-75 imgShadow'/>
</div>

</Parallax>

<div className='minScreenHeight centeredFlex topShadow refundingBackground adverisementDisplay text-white pb-5'>
    <h1 className='largeText1'>Looking for a refund?</h1>
<MDBBtn onClick={toggleShow} size='lg' color='light' className='imgShadow mb-5'>Click here for a refund</MDBBtn>
</div>


        {/* Modal */}

        
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
        <MDBModalDialog centered size="sm">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBBtn size='lg'  className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <h2>Refund?!?</h2>
                <p> What do you think this is?!?</p>
                
                
                <p>
                    <img src={rickRoll}/>
                </p>

                <p> $1.99 for a refund!</p>

            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' size="lg" onClick={toggleShow}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}
